import React, { Component } from "react"
// import AliceCarousel from "react-alice-carousel"
// import "react-alice-carousel/lib/alice-carousel.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/LogoCarousel.scss"

//Images
import Aws from "../images/aws.png"
import Cloudbolt from "../images/cloudbolt.png"
import Spectro from "../images/spectro-cloud.png"
import Cisco from "../images/cisco.png"
import Netbrain from "../images/netbrainT.png"
import Ao from "../images/ao.png"
import Vmware from "../images/vmware.png"
import Bell from "../images/bell-int.png"

import { GrMoney } from "react-icons/gr"
import { GiRadioTower } from "react-icons/gi"
import { BiHealth } from "react-icons/bi"
import { RiGovernmentLine } from "react-icons/ri"

// const responsive = {
//   0: { items: 1 },
//   650: { items: 2 },
//   850: { items: 3 },
//   1600: { items: 4 },
// }

// const IndustryCarousel = () => (
//   <AliceCarousel
//     autoPlay
//     infinite
//     mouseTracking
//     responsive={responsive}
//     disableButtonsControls
//     disableDotsControls
//     autoPlayInterval={1000}
//   >
//     <div className="inner-container-5 client-card noselect">
//       <div className="industry-carousel">
//         <GiRadioTower className="icon" />
//         <h4>Global Telecommunications</h4>
//       </div>
//     </div>
//     <div className="inner-container-5 client-card noselect">
//       <div className="industry-carousel">
//         <RiGovernmentLine className="icon" />
//         <h4>US Federal</h4>
//       </div>
//     </div>
//     <div className="inner-container-5 client-card noselect">
//       <div className="industry-carousel">
//         <BiHealth className="icon" />
//         <h4>UK Healthcare</h4>
//       </div>
//     </div>
//     <div className="inner-container-5 client-card noselect">
//       <div className="industry-carousel">
//         <GrMoney className="icon" />
//         <h4>Global Financial Services</h4>
//       </div>
//     </div>
//   </AliceCarousel>
// )

// export default IndustryCarousel

export default class LogoCarousel extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      arrows: true,
      // dots: true,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },

        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return (
      <div>
        <Slider {...settings}>
          <div
            className="inner-container-5  client-card noselect"
            style={{ display: "flex" }}
          >
            <div className="industry-carousel">
              <GiRadioTower className="icon" />
              <h4>Global Telecommunications</h4>
            </div>
          </div>
          <div
            className="inner-container-5 client-card noselect"
            style={{ display: "flex" }}
          >
            <div className="industry-carousel">
              <RiGovernmentLine className="icon" />
              <h4>US Federal</h4>
            </div>
          </div>
          <div
            className="inner-container-5 client-card noselect"
            style={{ display: "flex" }}
          >
            <div className="industry-carousel">
              <BiHealth className="icon" />
              <h4>UK Healthcare</h4>
            </div>
          </div>
          <div
            className="inner-container-5 client-card noselect"
            style={{ display: "flex !important" }}
          >
            <div className="industry-carousel">
              <GrMoney className="icon" />
              <h4>Global Financial Services</h4>
            </div>
          </div>
        </Slider>
      </div>
    )
  }
}
