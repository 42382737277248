import React, { useEffect, useState } from "react"
import { FaChevronCircleUp } from "react-icons/fa"
import "../styles/ScrollArrow.scss"

const ScrollArrow = ({ showBelow }) => {
  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }
  const [show, setShow] = useState(showBelow ? false : true)
  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }
  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })
  return (
    <div>
      {show && (
        <FaChevronCircleUp className="to-top-button" onClick={handleClick} />
      )}
    </div>
  )
}

export default ScrollArrow
