import React from "react"
import "../styles/Card.scss"
import { MdVisibility } from "react-icons/md"
import { CgSmartphoneChip } from "react-icons/cg"
import { BiServer } from "react-icons/bi"
import { BiSearchAlt } from "react-icons/bi"
import { FaCogs } from "react-icons/fa"

const Card = props => {
  return (
    <a href={props.href}>
      <div className="view view-fifth">
        <div className="droplet" style={{ color: "white" }}>
          {props.visibility ? (
            <BiSearchAlt
              style={{ color: "white", height: "100%", width: "100%" }}
            />
          ) : props.automation ? (
            <CgSmartphoneChip
              style={{ color: "white", height: "100%", width: "100%" }}
            />
          ) : props.application ? (
            <BiServer
              style={{ color: "white", height: "100%", width: "100%" }}
            />
          ) : (
            <FaCogs style={{ color: "white", height: "100%", width: "100%" }} />
          )}
        </div>

        <h3>{props.heading}</h3>

        <div className="mask">
          <h4>{props.heading}</h4>
          <p>{props.info}</p>
          <div className="link">Read More</div>
        </div>
      </div>
    </a>
  )
}

export default Card
