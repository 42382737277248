import React from "react"
import "./birds.scss"

const Birds = ({ children }) => {
  return (
    <div>
      <div className="birds-container">
        {children}
        <div className="bird-container bird-container--one">
          <div className="bird bird--one"></div>
        </div>

        <div className="bird-container bird-container--two">
          <div className="bird bird--two"></div>
        </div>

        <div className="bird-container bird-container--four">
          <div className="bird bird--four"></div>
        </div>
      </div>
    </div>
  )
}

export default Birds
