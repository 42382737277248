import React, { useEffect, useRef } from "react"
import "../styles/Index.scss"
import "../styles/AppModModal.scss"
import "../styles/SecurityModal.scss"
import { Link, graphql, useStaticQuery, navigate } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { StaticImage } from "gatsby-plugin-image"
import ScrollAnimation from "react-animate-on-scroll"

import "animate.css/animate.min.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from "../components/Button"
import ButtonB from "../components/ButtonB"
import Birds from "../components/Birds/birds"
import Card from "../components/Card"
import ContactForm from "../components/ContactForm"
import ScrollArrow from "../components/ScrollArrow"
import AppModModal from "../components/ScrollArrow"
import LogoCarousel from "../components/LogoCarousel"
import Thumbnail from "../images/thumbnail.jpg"

import { gsap, TweenMax, Power3 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import IndustryCarousel from "../components/IndustryCarousel"
//import InteractiveImageDemo from "../components/InteractiveImageDemo"

//Icons
import { IoIosArrowDown } from "react-icons/io"
import { BiSearchAlt } from "react-icons/bi"
import { FaRegCircle } from "react-icons/fa"
import { FaCircle } from "react-icons/fa"

//Images
import Video from "../videos/home.mp4"
import Logo from "../images/logo-dark.png"
import Leaf from "../images/leaf-anim.png"
import Leaf2 from "../images/leaf-2.png"
import MapImg from "../images/map.png"
import Divider from "../images/Blue-line.png"
import Automation from "../svgs/automation.svg"
import Application from "../svgs/application.svg"
import UseCases from "../svgs/Use-cases.svg"
import Blog from "../svgs/Blog.svg"
import News from "../svgs/News.svg"

import Cisco from "../images/cisco.png"

const IndexPage = () => {
  gsap.registerPlugin(ScrollTrigger)
  //gatsby-bg-image query
  const indexData = useStaticQuery(graphql`
    query indexImages {
      hero: file(relativePath: { eq: "hero-2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      forest: file(relativePath: { eq: "forest-3.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      water: file(relativePath: { eq: "water.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      clouds: file(relativePath: { eq: "clouds.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  let leaf = useRef()
  let leaf2 = useRef()

  //gsap
  useEffect(() => {
    TweenMax.from(".leaf", 1.5, {
      scrollTrigger: {
        trigger: ".transforming-it",
        start: "-100 center",
        end: "bottom center",
        toggleActions: "play none none none",
        //markers: true,
      },
      opacity: 0,
      y: -600,
      ease: Power3.ease,
      delay: 2,
    })
  }, [])
  useEffect(() => {
    TweenMax.to(".leaf-2", 1.5, {
      scrollTrigger: {
        trigger: ".transforming-it",
        start: "100 center",
        end: "bottom center",
        scrub: 0.7,
      },
      y: -600,
      ease: "none",
      rotation: 50,
    })
  }, [])

  const goToSummit = () => {
    window.open("https://summit.metsi.co")
    document.querySelector(".app-mod-modal").classList.add("click")
  }

  const closeSummit = () => {
    document.querySelector(".app-mod-modal").classList.add("click")
  }

  const goToSecurity = () => {
    window.open("https://blog.metsi.com/metsi-acquires-virtigon", "_blank")
    // document.querySelector(".security-modal").classList.add("click")
  }

  const handleClick = event => {
    // If the event target has the class 'security-close', close the modal
    if (event.target.classList.contains("security-close")) {
      closeSecurity()
      return
    } else {
      // otherwise, navigate to '/finops'
      console.log("navigating...")
      navigate("/finops")
    }
  }

  const closeSecurity = () => {
    document.querySelector(".security-modal").classList.add("click")
  }

  return (
    <Layout>
      <SEO
        title="Leading Cloud Computing"
        keywords={["cloud solutions", "business agility"]}
        lang="en-gb"
        description="Metsi Technologies helps customers achieve digital maturity by accelerating digital transformation. We create secure IT flexibility that meets business demands."
      />
      <ScrollArrow showBelow={450} />
      {/* <div className="app-mod-modal">
        <div>
          <div className="app-modal-close" onClick={closeSummit}>
            X
          </div>
          <br />
          <p onClick={goToSummit} style={{ margin: "0" }}>
            Take the Journey to Application Modernisation
          </p>
        </div>
      </div> */}
      <div className="security-modal" onClick={e => handleClick(e)}>
        <div>
          <div className="security-close">X</div>
          <br />
          <p>
            New At Metsi: <br />
            FinOps Practice
          </p>
        </div>
      </div>
      {/*********** Hero *************/}
      <BackgroundImage
        fluid={indexData.hero.childImageSharp.fluid}
        className="hero-section"
        style={{
          width: "100vw",
          height: "100vh",
          margin: 0,
          padding: 0,
          position: "relative",
          display: "flex",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a href="#arrow" className="icon-container">
          <IoIosArrowDown className="arrow-icon" id="arrow" />
        </a>
        <Birds>
          <div className="hero-wrapper">
            <div className="hero-content">
              <div className="transparent">
                <div className="outer-container">
                  <div className="row">
                    <div className="inner-container-2 left">
                      <h1>Metsi: Helping customers achieve digital maturity</h1>
                      <img src={Divider} alt="divider" className="divider" />
                      <p style={{ color: "#000" }}>
                        Metsi accelerates digital transformation for the IT
                        enterprise through continuous digital innovation,
                        digital applications, hybrid multicloud adoption,
                        microservices, and Anything-as-a-Service. We create the
                        secure IT flexibility that meets business demand.
                      </p>

                      {/* <ButtonB href="#transforming-it">Find Out How</ButtonB> */}
                    </div>
                    <div className="inner-container-2 right">
                      <StaticImage
                        src="../images/logo-dark.png"
                        alt="logo"
                        placeholder="blurred"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Birds>
      </BackgroundImage>
      {/*********** Transforming IT *************/}
      <div
        className="half-section transforming-it"
        id="transforming-it"
        style={{ position: "relative", height: "100%" }}
      >
        <img src={Leaf} alt="leaf" className="leaf" ref={el => (leaf = el)} />
        <img
          src={Leaf2}
          alt="leaf"
          className="leaf-2"
          ref={el => (leaf2 = el)}
        />

        <div className="outer-container">
          <h2>Transforming IT</h2>
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <h1 style={{ textAlign: "center" }}>
              Business agility through service innovation
            </h1>
          </ScrollAnimation>

          <div className="row" style={{ marginTop: "-36px" }}>
            {/* <div
              className="inner-container-2"
              style={{ padding: "0px", justifyContent: "flex-start" }}
            >
              <video
                controls
                style={{ width: "85%", minHeight: "200px", paddingTop: "36px" }}
                poster={Thumbnail}
              >
                <source src={Video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div> */}
            <div
              className="inner-container-2"
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>
                Metsi enables customers to
                <strong> accelerate </strong>
                their journey to <strong> digital maturity </strong> by aligning
                to their IT and business goals.
              </p>
              <p>
                We do this by taking a <strong>service-centric approach</strong>{" "}
                to
                <strong> application and platform modernisation,</strong> making
                it simpler to <strong>define, deliver, manage</strong> and
                consume
                <strong> secure</strong> services.
              </p>
              <p>
                Our expert teams build intelligent solutions based on
                data-driven insights, to provide outcomes that drive
                <strong> business agility</strong> and
                <strong> service innovation.</strong>
              </p>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "cener",
                }}
              >
                <ButtonB href="/about" style={{ alignSelf: "center" }}>
                  Who we are
                </ButtonB>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*********** Services *************/}
      <BackgroundImage
        fluid={indexData.forest.childImageSharp.fluid}
        className="services-section"
        style={{
          width: "100vw",
          height: "100%",
          margin: 0,
          padding: 0,
          position: "relative",
          display: "flex",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="services-overlay">
          <div className="outer-container">
            <h2 className="light-h">Services</h2>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1.3}>
              <h1 className="light-h">
                Value through a unique range of services
              </h1>
            </ScrollAnimation>
            <div className="outer-container">
              <div className="row">
                {/******** Desktop Cards *******/}
                <div className="desktop-card">
                  <div className="inner-container-3">
                    <Card
                      heading="Application Management"
                      href="/application-management"
                      visibility="true"
                      info="An enterprise wide approach for managing the lifecycle, provisioning and performance of applications together with the end-to-end delivery of IT services 
                      to customers. This includes all the processes and activities to design, create, deliver, and support IT services.
                            Application Management helps businesses deliver consistently flawless digital experiences. 
                      "
                    />
                  </div>
                </div>
                <div className="desktop-card">
                  <div className="inner-container-3">
                    <Card
                      heading="Service Orchestration"
                      href="/service-orchestration"
                      automation="true"
                      info="Automation is about using APIs to stitch together workloads for true end-to-end service orchestration.
                   Metsi solutions deliver the benefits of IT automation & orchestration across the digital enterprise: in software 
                   defined networking, digital applications, microservices, multicloud and hybrid cloud, and IT as a Service.
                      "
                    />
                  </div>
                </div>
                <div className="desktop-card">
                  <div className="inner-container-3">
                    <Card
                      heading="Full Stack Engineering"
                      href="/full-stack-engineering"
                      application="true"
                      info="Metsi specialises in complex systems automation & orchestration, built on a multicloud or hybrid cloud fabric, and empowered by a 
                      Software Defined Data Center. Our software engineers are experts in self-service platforms, real-time business performance and optimisation, 
                      and automated control of network infrastructure. "
                    />
                  </div>
                </div>

                <div className="desktop-card">
                  <div className="inner-container-3">
                    <Card
                      heading="Services"
                      href="/services"
                      services="true"
                      info="Our investment in the technical architecture team has been a recipe of differentiation and a catalyst for securing critical
                       long-term contracts and new customers. The ability to lead with Professional Services has also allowed us to become a confident and
                        trusted adviser to many of our customers and ensures that our reach goes deeper and wider across the customers IT Landscape.
                      "
                    />
                  </div>
                </div>

                {/******** Mobile Cards *******/}
                <div className="mobile-card">
                  <div className="inner-container-3 ">
                    <div className="droplet">
                      <BiSearchAlt className="icon" />
                    </div>
                    <h3>Application Management</h3>
                    <p>
                      An enterprise wide approach for managing the lifecycle,
                      provisioning and performance of applications together with
                      the end-to-end delivery of IT services to customers. This
                      includes all the processes and activities to design,
                      create, deliver, and support IT services. Customers demand
                      flawless performance and they’re loyal to the brands that
                      deliver it. That’s the new reality. Application Management
                      helps businesses deliver consistently flawless digital
                      experiences by monitoring, correlating, analysing and
                      acting on application and business performance data in
                      real time.
                    </p>
                    <Button to="/application-management">Learn More</Button>
                  </div>
                </div>

                <div className="mobile-card">
                  <div className="inner-container-3 ">
                    <div
                      className="droplet"
                      style={{ padding: "20px 10px 20px 30px" }}
                    >
                      <Automation className="icon" />
                    </div>
                    <h3>Service Orchestration</h3>
                    <p>
                      Automation is more than just being able to spin up a
                      infrastructure components quickly. It’s about using APIs
                      to stitch together workloads across storage, network and
                      compute, ITSM, CMDB, helpdesk, monitoring systems and
                      asset management for true end-to-end service
                      orchestration. Metsi solutions deliver the benefits of IT
                      automation & orchestration across the digital enterprise:
                      in software defined networking, digital applications,
                      microservices, multicloud and hybrid cloud, and IT as a
                      Service.
                    </p>
                    <Button to="/service-orchestration">Learn More</Button>
                  </div>
                </div>

                <div className="mobile-card">
                  <div className="inner-container-3">
                    <div className="droplet">
                      <Application className="icon" />
                    </div>
                    <h3>Full Stack Engineering</h3>
                    <p>
                      Metsi specialises in complex systems automation &
                      orchestration, built on a multicloud or hybrid cloud
                      fabric, and empowered by a Software Defined Data Center.
                      Our software engineers are experts in self-service
                      platforms, real-time business performance and
                      optimisation, and automated control of network
                      infrastructure.
                    </p>
                    <Button to="/full-stack-engineering">Learn More</Button>
                  </div>
                </div>

                <div className="mobile-card">
                  <div className="inner-container-3 ">
                    <div
                      className="droplet"
                      style={{ padding: "20px 10px 20px 30px" }}
                    >
                      <Automation className="icon" />
                    </div>
                    <h3>Services</h3>
                    <p>
                      Our engagement methodology is simple and we approach
                      complex problems with a fresh pair of eyes. Our investment
                      in the technical architecture team has been a recipe of
                      differentiation and a catalyst for securing critical
                      long-term contracts and new customers. The ability to lead
                      with Professional Services has also allowed us to become a
                      confident and trusted adviser to many of our customers and
                      ensures that our reach goes deeper and wider across the
                      customers IT Landscape.
                    </p>
                    <Button to="/services">Learn More</Button>
                  </div>
                </div>
              </div>
              <div className="inner-container">
                <p>
                  Metsi provides ongoing support for software-defined
                  infrastructures, and provides continued enhancements via
                  automation processes and leveraging open APIs used within a
                  software-defined architecture, to ensure our customers achieve
                  pre-defined results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/*********** Partners *************/}
      <div className="section partners">
        <div className="outer-container" style={{ display: "block" }}>
          <div
            className="droplet-blue-invert"
            style={{ marginTop: "25px", float: "left" }}
          >
            Our Partners
          </div>
          <div style={{ display: "block" }}>
            <LogoCarousel />
          </div>
          {/* <div className="cisco"><img src={Cisco} className="carousel-logo" /></div> */}
        </div>
      </div>

      {/*********** Elements *************/}
      <BackgroundImage
        fluid={indexData.water.childImageSharp.fluid}
        className="elements-section"
        style={{
          width: "100vw",
          height: "100%",
          margin: 0,
          padding: 0,
          position: "relative",
          display: "flex",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="outer-container">
          <h2 className="light-h">Metsi Elements</h2>
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1.3}>
            <h1 className="light-h">How our solutions improve your business</h1>
          </ScrollAnimation>

          <div className="row">
            <div className="inner-container-4 column">
              <StaticImage
                src="../images/drops.jpg"
                alt="water-drop"
                className="circle-outline"
                imgClassName="circle-outline-img"
                placeholder="blurred"
              />
              <h4>Data-Driven Governance & Security</h4>
              <p>
                Used correctly, data is your most precious asset. By helping you
                to define policy and processes, the value you can achieve from
                your data is enhanced, while still maintaining its integrity.
              </p>
            </div>

            <div className="inner-container-4 column">
              <StaticImage
                src="../images/moss.jpg"
                alt="moss"
                className="circle-outline"
                imgClassName="circle-outline-img"
                placeholder="blurred"
              />
              <h4>Digital Maturity</h4>
              <p>
                Businesses are experiencing a constant cycle of change in our
                digital age. Digital maturity is all about maximising the value
                of data-driven innovation through digital transformation. Metsi
                gets you ready to invest in the practical implementation of
                transformation, and helps you reap the rewards.
              </p>
            </div>
            <div className="inner-container-4 column">
              <StaticImage
                src="../images/ripple.jpg"
                alt="ripple"
                className="circle-outline"
                imgClassName="circle-outline-img"
                placeholder="blurred"
              />
              <h4>Application & Platform Modernisation</h4>
              <p>
                Modernising the platform infrastructure and architecture of
                existing legacy applications can improve the speed of new
                feature delivery and increase scale and performance that fits in
                with a broader, next-generation data centre and IT strategy.
              </p>
            </div>
            <div className="inner-container-4 column">
              <StaticImage
                src="../images/leaves.jpg"
                alt="leaves"
                className="circle-outline"
                imgClassName="circle-outline-img"
                placeholder="blurred"
              />
              <h4>Business Innovation & Service Agility</h4>
              <p>
                Innovation is about being smarter by defining and optimising new
                ways of doing business; solving problems; and delivering
                services faster to achieve cost reduction and profitability.
              </p>
            </div>
          </div>
          <div className="row" style={{ width: "60%" }}>
            <div className="inner-container-3" style={{ minWidth: "360px" }}>
              <div className="droplet-invert">
                <UseCases className="icon" />
              </div>
              <Button to="https://blog.metsi.com/use-cases/">Use Cases</Button>
            </div>
            {/* <div className="inner-container-3">
              <div className="droplet-invert">
                <Blog className="icon" />
              </div>
              <Button to="https://blog.metsi.com/service-packages/">
                Service Packages
              </Button>
            </div> */}
            <div className="inner-container-3">
              <div className="droplet-invert">
                <News className="icon" />
              </div>
              <Button to="https://blog.metsi.com/news/">News</Button>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/*********** Clients *************/}
      <div className="section clients">
        <div className="outer-container" style={{ display: "block" }}>
          <div
            className="droplet-blue-invert"
            style={{ marginTop: "25px", float: "left" }}
          >
            Client Industries
          </div>

          <IndustryCarousel />
        </div>
      </div>
      {/*********** Contact *************/}
      <div className="contact-section" id="contact">
        <div className="outer-container" style={{ paddingTop: "0" }}>
          <div className="contact-header">
            <h2>Contact Us</h2>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={1.3}>
              <h1 style={{ textAlign: "center" }}>
                Have a question about our solutions?
              </h1>
            </ScrollAnimation>
          </div>

          <div className="row">
            <div className="inner-container-2">
              <ContactForm />
            </div>
            <div className="inner-container-2">
              <div className="row">
                <div className="inner-container">
                  <StaticImage
                    src="../images/map.png"
                    alt="map"
                    placeholder="blurred"
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Office Locations</h4>
                  <ul>
                    <li>
                      <FaCircle className="icon" id="uk" />
                      UK
                    </li>
                    <li>
                      <FaCircle className="icon" id="usa" />
                      USA
                    </li>
                    <li>
                      <FaCircle className="icon" id="germany" />
                      Germany
                    </li>
                    <li>
                      <FaCircle className="icon" id="netherlands" />
                      The Netherlands
                    </li>
                    <li>
                      <FaCircle
                        className="icon"
                        id="india"
                        style={{ color: "transparent" }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Satellite Locations</h4>
                  <ul>
                    <li>
                      <FaRegCircle className="icon" id="belgium" />
                      Belgium
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="sa" />
                      South Africa
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="poland" />
                      Poland
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="spain" />
                      Spain
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="india" />
                      India
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
