import styled from "styled-components"
import { Link } from "gatsby"

export const Button = styled(Link)`
  background: #fff;
  white-space: nowrap;
  margin: 1rem 0;
  outline: none;
  border: 1px solid #105b85;
  font-weight: bold;
  width: 100%;
  max-width: 250px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  color: #005b85;
  font-size: 20px;
  border-radius: 5px;
  @media (max-width: 450px) {
    white-space: normal;
  }
  &:hover {
    /* transform: scale(1.02); */
    background: #005b85;
    color: #fff;
  }
`
//<a class="button arrow">Read More</a>
//a.button {
//   margin: 20px;
//   font-size: 20px;
//   transition: 0.3s ease-in-out;
// }
// a.button:hover{
//   background-color:lightblue;
//   transform: scale(1.05);
//   transform:translateY(-3px);
// }

// .arrow {
//   color: #0c5449;
//   background-color: #f6f3ed;
//   margin: 1em 0;

//   &::after {
//       display: inline-block;
//       padding-left: 8px;
//       content: "\0279E"; // arrow right unicode
//       -webkit-transition: transform 0.3s ease-out;
//       -moz-transition: transform 0.3s ease-out;
//       -ms-transition: transform 0.3s ease-out;
//       -o-transition: transform 0.3s ease-out;
//       transition: transform 0.3s ease-out;
//   }

//   &:hover {
//       color: #0c5449;
//       background-color: #f6f3ed;

//       &::after {
//           -webkit-transform: translateX(4px);
//           -moz-transform: translateX(4px);
//           -ms-transform: translateX(4px);
//           -o-transform: translateX(4px);
//           transform: translateX(4px);
//       }
//   }
// }
